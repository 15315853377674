import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { initializeApp } from "@firebase/app";
import { getStorage } from "@firebase/storage";
import { getFirestore } from "firebase/firestore/lite";
import { getAnalytics } from "@firebase/analytics";

// firebase設定情報
const firebaseConfig = {
  apiKey: "AIzaSyByY_G9sUprxNIsNWgpUSL80fI4BneT5Qg",
  authDomain: "my-list-count-ranking-of-tver.firebaseapp.com",
  projectId: "my-list-count-ranking-of-tver",
  storageBucket: "my-list-count-ranking-of-tver.appspot.com",
  messagingSenderId: "1065589233538",
  appId: "1:1065589233538:web:56a6365c4bc5261745619b",
  measurementId: "G-ZSLGK24NCT",
};

const app = initializeApp(firebaseConfig);
store.commit("setStorage", getStorage(app));
store.commit("setDb", getFirestore());
getAnalytics(app);

createApp(App).use(store).use(router).mount("#app");
