import store from '@/store';
import { updateContentsData } from './firebaseOperations';
import { pages } from './constants';

export function scrollFunc (page: any): () => Promise<void> {
  // スクロールのイベントリスナーに関するクロージャ
  let bodyElementList: any; // 表示対象ページのコンテンツに関する要素のリストを格納する変数
  let bodyElement: any; // コンテンツデータを、追加更新するための起点となる要素が格納される変数
  let updating = false; // 更新中かどうかを表す変数

  async function inner () {
    if (!updating) {
      // 更新中では無かった場合
      const type = store.state.detailType; // 表示するページの、paramsに指定されたタイプ(ホームページの場合は空文字が入る)
      let loadedEverything = false; // ロードが完了しているかどうか
      let contentsMaxLength = 0; // 現在ロードされているコンテンツの最大数
      if (page === pages.category) {
        // カテゴリーページの場合
        loadedEverything = store.state.categoryContents[type].loadedEverything; // 完了状態を代入
        contentsMaxLength = store.state.categoryContents[type].values.length; // 最大数を代入
      } else if (page === pages.soar) {
        // Soarページの場合
        loadedEverything = store.state.soaringContents[type].loadedEverything;
        contentsMaxLength = store.state.soaringContents[type].values.length;
      } else {
        loadedEverything = store.state.contents.loadedEverything;
        contentsMaxLength = store.state.contents.values.length;
      }

      bodyElementList = Array.from(
        document.getElementsByClassName('content-parent')
      ); // 現在表示されているコンテンツの要素に関するリストを代入

      bodyElement = bodyElementList.slice(contentsMaxLength - 10)[0]; // 追加更新の起点となる要素を代入

      if (!loadedEverything) {
        // コンテンツのロードが完了していない場合
        if (bodyElement !== undefined) {
          // 起点となる要素が正常に設定されている場合
          const rect = bodyElement.getBoundingClientRect(); // 起点となる要素の位置に関するデータを代入
          const isInView = rect.bottom > 0 && rect.top < window.innerHeight; // 表示中かどうかを表すbool値を代入
          if (isInView) {
            // 表示中の場合
            updating = true; // 更新中の状態を表現するために、trueを代入
            await updateContentsData(page, true, ''); // コンテンツの追加更新を行う
            updating = false; // 更新中解除を表現するために、falseを代入
          }
        }
      }
    }
  }

  return inner;
}
