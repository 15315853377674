
import { defineComponent, reactive } from "vue";
import { useRouter } from "vue-router";
import store from "@/store";
import { pages, categories } from "@/components/constants";

export default defineComponent({
  props: {
    index: {
      // 順位(0始まり)
      type: Number,
      required: true,
    },
    content: {
      // コンテンツデータ
      type: Object,
      required: true,
    },
    isAd: {
      type: Boolean,
      required: true,
    },
  },

  async setup(props) {
    const router = useRouter();

    const state = reactive({
      content: JSON.parse(JSON.stringify(props.content)), // 深いコピーをしたコンテンツデータ
      ranking: props.index + 1, // ランキング
      isCategoryPage: router.currentRoute.value.name === pages.category,
      displayDiffRankingFlag:
        router.currentRoute.value.name === pages.home ||
        store.state.detailType === categories.ranking, // 順位が前回と比べてどうであったかを表示するかどうかを表すbool("Homeページ表示時"か、"Soarページ表示時であり、かつ、typeがranking"の場合)
      diffRanking: {
        // 順位が前回と比べてどれくらい変化したか
        count: 0,
      } as any,
      diffMylistCount: {
        // お気に入り数が前回と比べてどれくらい変化したか
        count: 0,
      } as any,
      isNewContent: false, // 新しく追加されたコンテンツかどうかを表すbool
      isAd: props.isAd,
      adPath: "@/assets/images/poi_ads/1.png",
    });

    const getRandomInt = (max: number) => {
      return Math.floor(Math.random() * max);
    };

    const errorImage = (element: any) => {
      // 画像の有効期限が切れており、表示できなかった場合に、代替画像を表示するための関数
      element.target.src = require("@/assets/images/brokenLink.png"); // 予め用意しておいたリンク切れ用画像を代入
    };

    const cleansingCount = (count: number) => {
      // 数字にカンマ(,)を入れて返す
      return count.toLocaleString();
    };

    state.adPath = require(`@/assets/images/poi_ads/${getRandomInt(3)}.png`);
    state.isNewContent = state.content.ranking.previous === 10000000; // 新しくTverに追加された番組だった場合

    if (state.displayDiffRankingFlag) {
      // 前回順位との差分を表示する場合
      state.diffRanking.count = state.content.diff_ranking_soaring; // 差分を代入
      if (state.diffRanking.count > 0) {
        // 0より大きい場合
        state.diffRanking.operator = "▲";
      } else if (state.diffRanking.count === 0) {
        // 0の場合
        state.diffRanking.operator = "±";
      } else {
        // 0より小さい場合
        state.diffRanking.operator = "▼";
      }

      state.diffRanking.count = cleansingCount(
        // クレンジング
        Math.abs(state.diffRanking.count) // 整数にする(符号は、operatorで表示するため)
      );
      state.content.ranking.current = cleansingCount(
        // クレンジング
        state.content.ranking.current
      );
    }
    state.diffMylistCount.count =
      state.content.mylist_count.current -
      (state.content.mylist_count.previous === -1
        ? 0
        : state.content.mylist_count.previous); // 今回のお気に入り数と、前回のお気に入り数との差分を代入
    if (state.diffMylistCount.count > 0) {
      // 0より大きい場合
      state.diffMylistCount.operator = "+";
    } else if (state.diffMylistCount.count === 0) {
      // 0の場合
      state.diffMylistCount.operator = "±";
    } else {
      // 0より小さい場合
      state.diffMylistCount.operator = "-";
    }

    state.diffMylistCount.count = cleansingCount(
      // クレンジング
      Math.abs(state.diffMylistCount.count) // 整数にする(符号は、operatorで表示するため)
    );
    state.content.mylist_count.current = cleansingCount(
      // クレンジング
      state.content.mylist_count.current
    );

    const movePageTop = () => {
      window.scrollTo(0, 0); // ページ最上部にする
    };

    return {
      state,
      store,
      router,
      errorImage,
      movePageTop,
    };
  },
});
