import { createStore } from 'vuex';
import { pages, categories } from '@/components/constants';

export default createStore({
  state: {
    contents: {
      // Homeページに関するコンテンツデータが入る変数
      values: [] as any, // コンテンツデータ本体
      lastSnapshot: undefined as any, // valuesに格納されているコンテンツデータの、最後のスナップショットが入る変数
      loadedEverything: false // 表示対象のコンテンツが、全て読み込めているかどうかを表す変数
    },
    categoryContents: {} as any, // 各カテゴリ名をkeyとして、valueにはcontentsで示しているmapが入る
    soaringContents: {} as any, // 各Soarページに関するタプをkeyとして、valueにはcontentsで示しているmapが入る
    categoryList: {
      // カテゴリ名
      drama: 'ドラマ',
      variety: 'バラエティ',
      anime: 'アニメ',
      news_documentary: '報道・ドキュメンタリー',
      sports: 'スポーツ',
      other: 'その他'
    },
    contentsUpdatedDate: undefined as any, // コンテンツ更新日
    detailType: '' as any, // 表示対象ページに関するタイプ(drama, variety, ranking等)
    numberOfContentBreak: 25,
    db: null as any,
    storage: null as any,
  },
  mutations: {
    setInit (state) {
      // コンテンツをsetする(まだ1つも表示ページに関して、データが入っていない場合のみ実行される)
      for (const key in state.categoryList) {
        state.categoryContents[key] = {
          // タイプをkeyとして代入
          values: [] as any, // コンテンツデータ本体
          lastSnapshot: undefined as any,
          loadedEverything: false
        };
      }
      for (const category of [categories.ranking, categories.mylistCount]) {
        state.soaringContents[category] = {
          // タイプをkeyとして代入
          values: [] as any, // コンテンツデータ本体
          lastSnapshot: undefined as any,
          loadedEverything: false
        };
      }
    },
    appendContents (state, data) {
      // 表示対象コンテンツの追加を行う関数
      if (data.page === pages.category) {
        // カテゴリページだった場合
        state.categoryContents[data.detailType].values.push(data.content); // 追加
      } else if (data.page === pages.soar) {
        // Soarページだった場合
        state.soaringContents[data.detailType].values.push(data.content); // 追加
      } else {
        // Homeページだった場合
        state.contents.values.push(data.content);
      }
    },
    setLastSnapshot (state, data) {
      // 現在読み込んでいるコンテンツデータに関して、最後のデータに関するスナップショットをsetするための関数
      if (data.page === pages.category) {
        state.categoryContents[data.detailType].lastSnapshot =
          data.lastSnapshot;
      } else if (data.page === pages.soar) {
        state.soaringContents[data.detailType].lastSnapshot = data.lastSnapshot;
      } else {
        state.contents.lastSnapshot = data.lastSnapshot;
      }
    },
    setLoadedEverything (state, data) {
      // 表示対象のコンテンツデータについて、全て読み込めているかどうかをsetするための関数
      if (data.page === pages.category) {
        state.categoryContents[data.detailType].loadedEverything =
          true;
      } else if (data.page === pages.soar) {
        state.soaringContents[data.detailType].loadedEverything =
          true;
      } else {
        state.contents.loadedEverything = true;
      }
    },
    setContentsUpdatedDate (state, date) {
      // コンテンツ更新日をsetするための関数
      state.contentsUpdatedDate = date;
    },
    setDetailType (state, type) {
      // 表示するページのタイプ(drama, variety, rankingなど)をsetするための関数
      state.detailType = type;
    },
    setDb (state, db) {
      state.db = db;
    },
    setStorage (state, storage) {
      state.storage = storage;
    }
  },
  actions: {},
  modules: {}
});
