import store from "@/store";
import {
  collection,
  getDocs,
  query,
  where,
  orderBy,
  limit,
  startAfter,
  QueryConstraint,
} from "firebase/firestore/lite";
import { pages, categories } from "./constants";

export async function updateContentsData(
  page: any,
  isUpdate: boolean,
  detailType: string
): Promise<any> {
  // 番組コンテンツをsetするための関数
  const queryParams: QueryConstraint[] = [where("is_enable", "==", true)];
  detailType = detailType.length === 0 ? store.state.detailType : detailType;

  if (page === pages.category) {
    // カテゴリページの場合
    queryParams.push(where("category", "==", detailType));
    queryParams.push(orderBy("ranking.current", "asc"));

    if (isUpdate) {
      queryParams.push(
        startAfter(store.state.categoryContents[detailType].lastSnapshot)
      );
    }
  } else if (page === pages.soar) {
    // Soarページの場合
    if (detailType === categories.ranking) {
      // タイプがrankingの場合
      queryParams.push(orderBy("diff_ranking_soaring", "desc"));
    } else {
      // タイプがmylist-countの場合
      queryParams.push(orderBy("diff_mylist_count_soaring_ranking", "desc"));
    }

    queryParams.push(orderBy("title", "asc"));

    if (isUpdate) {
      queryParams.push(
        startAfter(store.state.soaringContents[detailType].lastSnapshot)
      );
    }
  } else {
    // Homeページの場合
    queryParams.push(orderBy("ranking.current", "asc"));
    if (isUpdate) {
      queryParams.push(startAfter(store.state.contents.lastSnapshot));
    }
  }

  queryParams.push(limit(store.state.numberOfContentBreak));

  const snapshot = await getDocs(
    query(collection(store.state.db, "contents"), ...queryParams)
  );

  // indexエラーなどのエラーチェックのための処理
  // let snapshot:any
  // ref.get().then(function (doc) {
  //   console.log(doc)
  // }).catch(function(error) {
  //   console.log('error ->', error)
  // })

  const contents = [] as any; // コンテンツデータが入るリスト
  let lastSnapshot; // 取得済みのコンテンツデータに関して、最後のスナップショットが入る変数
  let index = 0;
  snapshot.forEach((doc: any) => {
    // 取得したコンテンツの数だけ回す
    const content = doc.data();
    store.commit("appendContents", {
      page: page,
      content: content,
      detailType: detailType,
    }); // 取得したデータを、該当箇所に追加更新
    contents.push(content);
    index++;

    lastSnapshot = doc;
  });

  store.commit("setLastSnapshot", {
    // 取得した最後のスナップショットをset
    page: page,
    lastSnapshot: lastSnapshot,
    detailType: detailType,
  });

  if (index === 0 || index !== store.state.numberOfContentBreak) {
    // 全てダウンロードし終えた場合
    store.commit("setLoadedEverything", {
      // 全てダウンロードし終えた事を表すために、storeの該当箇所のloadedEverythingに、trueを代入
      page: page,
      detailType: detailType,
    });
  }

  if (
    contents !== undefined &&
    contents.length !== 0 &&
    store.state.contentsUpdatedDate === undefined
  ) {
    // setしたコンテンツデータが正常に取得できており、かつ、更新日がまだsetされていなかった場合
    setUpdateDate(contents[0]);
  }

  return contents;
}

// 日付をYYYY-MM-DDの書式で返すメソッド
const formatDate = (dt: any) => {
  const y = dt.getFullYear();
  const m = ("00" + (dt.getMonth() + 1)).slice(-2);
  const d = ("00" + dt.getDate()).slice(-2);
  const h = dt.getHours();
  const min = ("00" + dt.getMinutes()).slice(-2);
  return `${y}/${m}/${d} ${h}:${min}`;
};

// コンテンツ更新日をsetするための関数
const setUpdateDate = (content: any) => {
  if (store.state.contentsUpdatedDate === undefined) {
    // まだsetされていなかった場合
    const updatedDate = new Date(content.updated_at.seconds * 1000);
    updatedDate.setHours(updatedDate.getHours() - 9); // 日本時間に整形
    store.commit("setContentsUpdatedDate", formatDate(updatedDate));
  }
};
