
import { defineComponent, reactive, onMounted } from "vue";
import store from "@/store";
import Content from "@/components/parts/Content.vue";
import TopTenContent from "@/components/parts/TopTenContent.vue";
import { onBeforeRouteLeave, useRouter } from "vue-router";
import { scrollFunc } from "@/components/contentsOperations";
import Flickity from "vue-flickity";
import { pages, categories } from "@/components/constants";

export default defineComponent({
  name: pages.home,
  components: {
    Content,
    TopTenContent,
    Flickity,
  },
  mounted() {
    // 何故か以下のaddEventListenerが動作しないため、代替案を用いてresizeしている。
    // window.addEventListener("load", () => {
    //   this.resizeFlickity();
    // });
    var i = 0;
    const interval = setInterval(() => {
      this.resizeFlickity();
      i++;
      if (i > 50) {
        clearInterval(interval);
      }
    }, 100);
  },
  setup() {
    const router = useRouter();

    const state = reactive({
      scrollFunc: scrollFunc(router.currentRoute.value), // スクロール時に実行されるクロージャ,
      flickityOptions: {
        initialIndex: 0,
        prevNextButtons: false,
        pageDots: false,
        freeScroll: true,
        contain: true,
        autoPlay: 4000,
      },
    });

    onMounted(() => {
      if (!store.state.contents.loadedEverything) {
        // 番組コンテンツのロードが完了していない場合
        window.addEventListener("scroll", state.scrollFunc); // 逐次追加のためのスクロールイベントを追加
      }
    });

    onBeforeRouteLeave(() => {
      window.removeEventListener("scroll", state.scrollFunc); // スクロールイベント削除
    });

    const displayedAdCount = () => {
      // 数字にカンマ(,)を入れて返す
      return Math.floor(store.state.contents.values.length / 15);
    };

    return {
      store,
      state,
      categories,
      displayedAdCount,
    };
  },
  methods: {
    resizeFlickity() {
      (this.$refs.rankingFlickity as any).resize();
      (this.$refs.mylistCountFlickity as any).resize();
    },
  },
});
