
import { defineComponent, reactive } from 'vue';
import { useRouter } from 'vue-router';
import store from '@/store';

export default defineComponent({
  props: {
    index: {
      // 順位(0始まり)
      type: Number,
      required: true
    },
    content: {
      // コンテンツデータ
      type: Object,
      required: true
    }
  },

  async setup (props) {
    const router = useRouter();

    const state = reactive({
      content: JSON.parse(JSON.stringify(props.content)), // 深いコピーをしたコンテンツデータ
      ranking: props.index + 1 // ランキング
    });

    const errorImage = (element: any) => {
      // 画像の有効期限が切れており、表示できなかった場合に、代替画像を表示するための関数
      element.target.src = require('@/assets/images/brokenLink.png'); // 予め用意しておいたリンク切れ用画像を代入
    };

    return {
      state,
      store,
      router,
      errorImage
    };
  }
});
