export const pages = {
  home: 'Home',
  category: 'Category',
  soar: 'Soar',
  aboutSite: 'AboutSite',
  privacyPolicy: 'PrivacyPolicy',
  termsOfUse: 'TermsOfUse'
};

export const categories = {
  mylistCount: 'mylist_count',
  ranking: 'ranking',
  drama: 'drama',
  variety: 'variety',
  anime: 'anime',
  sports: 'sports',
  other: 'other',
};
