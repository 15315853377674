
import { defineComponent, onMounted } from "vue";
import store from "@/store";
import { useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const router = useRouter();

    onMounted(() => {
      const headerLinkElement = document.getElementById("header-link") as any; // ヘッダー部分の、各ページに遷移できるリンクに関する要素を代入
      window.addEventListener("scroll", () => {
        // スクロールイベントリスナ
        if (headerLinkElement !== undefined) {
          // 要素が取得できている場合
          const rect = headerLinkElement.getBoundingClientRect(); // 位置に関する値を取得
          const isInView = rect.bottom > 0 && rect.top < window.innerHeight; // 表示中かどうかを表すbool
          const fullScreenMenu = document.getElementById("full-screen-menu");
          if (fullScreenMenu != null) {
            if (isInView) {
              // 表示中だった場合
              fullScreenMenu.style.display = "none"; // ハンバーガーメニューを非表示状態にする
            } else {
              // 各ページに遷移できるリンクに関する要素が表示されていない場合
              fullScreenMenu.style.display = "flex"; // ハンバーガーメニューを表示する
            }
          }
        }
      });
    });

    const transitionPageFromFullScreenMenu = (url: string) => {
      // フルスクリーンメニュー内のリンクをクリックした場合
      (document.getElementById("toggle") as HTMLInputElement).checked = false; // チェックハンバーガーメニューのチェック状態をoffにする
      window.scrollTo(0, 0); // ページ最上部にする
      router.push({
        // 遷移
        path: url,
      });
    };

    return {
      router,
      store,
      transitionPageFromFullScreenMenu,
    };
  },
});
