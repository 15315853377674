import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";
import store from "@/store";
import { pages, categories } from "@/components/constants";
import { updateContentsData } from "@/components/firebaseOperations";

const routes: Array<RouteRecordRaw> = [
  {
    // マッチしないページが指定された場合
    path: "/:pathMatch(.*)*",
    redirect: "/",
  },
  {
    path: "/",
    name: pages.home,
    component: Home,
    meta: { title: "ホーム" },
  },
  {
    path: "/category/:type",
    name: pages.category,
    component: () => import("../views/Category.vue"),
    meta: {},
  },
  {
    path: "/soar/:type",
    name: pages.soar,
    component: () => import("../views/Soar.vue"),
    meta: {},
  },
  {
    path: "/about-site",
    name: pages.aboutSite,
    component: () => import("../views/AboutSite.vue"),
    meta: {},
  },
  {
    path: "/privacy-policy",
    name: pages.privacyPolicy,
    component: () => import("../views/PrivacyPolicy.vue"),
    meta: {},
  },
  {
    path: "/terms-of-use",
    name: pages.termsOfUse,
    component: () => import("../views/TermsOfUse.vue"),
    meta: {},
  },
];

const DEFAULT_TITLE = "TVerお気に入り数ランキング";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  document.title = DEFAULT_TITLE;
  let title = "";

  try {
    if (to.name === pages.category || to.name === pages.soar) {
      const type = (to.params.type as string).replace("-", "_");
      store.commit("setDetailType", type);

      if (to.name === pages.category) {
        title = (store.state.categoryList as any)[type as string];
      } else {
        if (to.name === pages.soar) {
          if (to.params.type === categories.ranking) {
            title = "総合ランキング急上昇";
          } else {
            title = "お気に入り数急上昇";
          }
        }
      }
    } else {
      store.commit("setDetailType", "");
      if (store.state.contents.values.length === 0) {
        // Homeページに関する、コンテンツデータがまだ1つもsetされていない場合
        await updateContentsData(pages.home, false, "");
      }
      if (store.state.soaringContents.ranking.values.length === 0) {
        await updateContentsData(pages.soar, false, categories.ranking); // 最初の番組データをset
      }
      if (store.state.soaringContents.mylist_count.values.length === 0) {
        await updateContentsData(pages.soar, false, categories.mylistCount); // 最初の番組データをset
      }
    }

    if (to.name !== pages.home) {
      document.title = title + " | " + DEFAULT_TITLE;
    }

    next();
  } catch (error) {
    next({ path: "/" });
  }
});

export default router;
