
import { defineComponent, reactive } from "vue";
import { useRouter } from "vue-router";
import store from "@/store";
import FullScreenMenu from "@/components/parts/FullScreenMenu.vue";
import { updateContentsData } from "@/components/firebaseOperations";
import { pages, categories } from "@/components/constants";

export default defineComponent({
  components: {
    FullScreenMenu,
  },
  setup() {
    const router = useRouter();

    // Soarページへの遷移に関する関数
    const transitionSoarPage = async (page: string, type: string) => {
      const cleansingType = type.replace("-", "_");
      store.commit("setDetailType", cleansingType);
      await updateContents(page);
      let path = "/";
      if (page === pages.category) {
        path = `/category/${type}`;
      } else if (page === pages.soar) {
        path = `/soar/${type}`;
      }

      router.push({
        path: path,
      });
    };

    const updateContents = async (page: string) => {
      if (
        (page === pages.home && store.state.contents.values.length === 0) ||
        (page === pages.category &&
          store.state.categoryContents[store.state.detailType as string].values
            .length === 0) ||
        (page === pages.soar &&
          store.state.soaringContents[store.state.detailType as string].values
            .length === 0)
      ) {
        // 開こうとしているカテゴリページに関する、コンテンツデータがまだ1つもsetされていない場合
        await updateContentsData(page, false, ""); // 最初の番組データをset
      }
    };

    const state = reactive({
      content: "",
    });

    return {
      state,
      router,
      store,
      pages,
      categories,
      transitionSoarPage,
    };
  },
});
