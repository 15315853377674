
import { defineComponent } from 'vue';
import Header from '@/components/parts/Header.vue';
import store from '@/store';

export default defineComponent({
  name: 'Base',
  components: {
    Header
  },
  setup () {
    store.commit('setInit');
  }
});
